export default {
    authorizedRedirections: [
      /^\/$/,
      /^\/publish/,
      /^\/login/,
      /^\/explorer/,
      /^\/portal/,
      /^\/discourse_sso/,
    ],
    client: {
      name: "Guyane-SIG",
      email: "guyane-sig@ctguyane.fr"
    },
    customHeaderAndFooter: true,
    forms: {
      signin: {
        title: {
          en: "Enter your login",
          fr: "Saisissez vos identifiants",
        }
      },
      signup: {
        disabled: false,
        hiddenFields: [],
        requiredFields: [
          'first_name',
          'last_name',
          'email',
        ],
      },
      organisation: {
        hiddenFields: [],
        requiredFields: [
          'name',
          'type',
        ],
      },
    },
  };
  
